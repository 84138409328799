.checkout-form {
    max-width: 800px;
    margin: 30px auto;
    .payment-info {
        display: flex;
        align-items:center;
    }
    .stripe-icon {
        height: 2rem;
    }
    .card-icons {
        margin-left: .5rem;
        height: 1.2rem;
    }
    .card-entry-container {
        margin-top: 5%;
    }

    .form-input-pair {
        padding-left: 30px;
        width: 90%;
        display: flex;
        justify-content: space-between;
        margin-bottom: 12px;
        input {
            width: 45%;
            border: none;
            font-size: 16px;
            color: #424770;
            &:focus {
                outline: none;
            }
        }
    }

    .StripeElement {
        margin-bottom: 12px;
    }
    hr {
        margin-bottom: 12px;
    }
    .form-input-pair.street-line-2 {
        .street-input {
            width: 44%;
        }
        .city-input {
            width: 36%;
        }
        .state-input {
            width: 12%;
        }
    }



    
    .checkout-form-body {
        transition: .2s;
        margin: 0 2vw;
        min-width: 600px;
    }
    .transparent {
        opacity: 0;
    }
    .form-validation-error {
        margin-top: 15px;
        margin-bottom: 20px;
    }
}
.card-entry-container {
    border: 1px solid rgb(185, 185, 185);
    padding: 10px;
}

.checkout-alert {
    width: 60%;
    max-width: 400px;
    margin: 2% auto;
}

tr.green-row {
    background: #a8d6a8
}


.button-group {
    display:flex;
    width:100%;
    margin-right: 0;
    .button-group-left-button {
        width: 33%;    
    }
    .button-group-center-button {
        width: 33%;
        
    }

    .button-group-right-button {
        width: 33%;
    }
}
.checkout-row td {
    margin-bottom: 5px;
    transition: .4s;
}

.highlighted-row td {
    border-top: 2px solid orange;
    border-bottom: 2px solid orange;
}
.highlighted-row td:first-child {
    border-left: 2px solid orange;
    border-radius: 4px 0px 0px 4px;
}
.highlighted-row td:last-child {
    border-right: 2px solid orange;
    border-radius: 0 4px 4px 0;
}

