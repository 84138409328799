.expired-token-alert {
    margin-bottom: 10px;
}

.form-validation-error {
    margin-top: 15px;
    margin-bottom: 20px;
}

.login-link {
    color: #fb8c00;
    font-size: 0.875rem;
    &:hover {
        text-decoration: underline;
    }
}