
.intake-card-container {
    


  .intake-card-controls {
      display: flex;
      justify-content: center;
  }
  
  .intake-card-bottom-row {
      display:flex;
      justify-content: space-between;
      .loa-container {
          width: 500px;
      }
  }
  .intake-card-title-bar {
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
  }

  a:hover {
      text-decoration: none;
  }
  .form-row {
      display: flex;
      margin: 5px auto;
      justify-content: space-around;
      align-items: center;
      width: 600px;
  }

  .notes-container {
      width: 600px;
      text-align: left;
      margin: 2% auto;
  }
}