.widget-container {
  .filter-form {
    display: inline-flex;
    gap: 10px;
    justify-content: center;
    @media (max-width: 700px) {
      flex-direction: column;
      width: 96%;
    }
  }

  .yoy-loader {
    @media (max-width: 700px) {
      display:none;
    }
  }

  .flex-row-center {
    display: flex;
    justify-content: center;
    gap: 10px;
  }

  .yoy-flex-nav-container {
    display: flex;
    @media (max-width: 700px) {
      margin-top: 16px;
    }
  }

  .table-column-title-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .yoy-content {
    width: 100%;
    margin-top: -10px;
  }

  .sort-header {
    user-select: none; 
    cursor: pointer;
  }
  .yoy-cell {
    border-color: rgba(224, 224, 224, 1);
    padding: 2px;
  }

  .resource-option {
    padding-left: 25px;
  }

  .horizontal-nav-container {
    // border-bottom: 1px solid #d0d0d0;
    // margin-bottom: 8px
  }

  .dark-mode {
    .yoy-cell {
      border-color: #444;
    }
    .horizontal-nav-container {
      border-color: #444;
      
    }
  } 

  .widget-controls {
    display: flex;
    justify-content: flex-end;
  }

  .chat-message {
    table, th, td {
      border: 1px solid gray;
      border-collapse: collapse;
    }
  }

  .chat-badge-container {
    display: flex;
    align-items: center;
  }

  .chat-message > div > p:first-child {
    margin-top: 0;
  }

  .chart-test-container {
    max-width: 1400px;
  }

  .chat-header {
    display: flex;
    align-items: center;
  }

  .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"] .MuiAutocomplete-endAdornment {
    top: 7px
  }
}