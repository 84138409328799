.App {
  text-align: center;
  min-height: 100vh;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}
.dark-mode {
  background: #212121;
  .css-19kwsvv-MuiButtonBase-root-MuiTab-root.Mui-selected.nav-tab {
    color: #eee;
    opacity: 1;
    border-bottom: 2px solid white;
  }

}


.css-19kwsvv-MuiButtonBase-root-MuiTab-root.Mui-selected.nav-tab {
  color: black;
  opacity: 1;
  border-bottom: 2px solid white;
}
.MuiTabs-flexContainer .PrivateTabIndicator-colorSecondary-3 {
  background-color: white;
}

.css-19kwsvv-MuiButtonBase-root-MuiTab-root.Mui-selected.nav-tab {
  color: black;
  opacity: .7;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}
.unstyled-link {
  color: inherit;
  text-decoration: inherit;
  &:hover {
    text-decoration: inherit;
  }
}
@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

a {
  text-decoration: none;

}
a:hover {
  text-decoration: underline;
}

.card-container {
  padding: 20px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  
}

.card-container > div {
  width: 300px;
  margin: 10px 10px 0px 0px;
}