.query-card {
  border: 1px solid grey;
  margin: 10px auto;
  width: 600px;
}

.dark-mode {
  color: #fff;
  .query-card {
    color: #fff;
  }
  
}

.forms-card {
  text-align: left;
}

.textract-meter-form {
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 20px;
  margin-top: 20px;

}

.note-form {
  display: flex;
  width: 1200px;
  flex-direction: column;
  margin: 0 auto;
  gap: 10px;
  margin-top: 20px;
}

.bill-save-errors {
  margin-top: 10px;
  .MuiAlert-root {
    border: 1px solid pink;
  }
}
.bill-nav {
  display: flex;
  justify-content: space-between;
  align-items: center;
  .textract-bill-header {
    display:flex;
    align-items:center;
  }
  .nav-controls {
    display: flex;

  }
  .nav-link {
    display: flex;
    align-items: center;
  }
}

.bill-creation-controls {
  display: flex;
  margin-top: 10px;
  justify-content: flex-end;
}

.control-button {
  margin: 10px;
}

.react-calendar-heatmap .saved-data-0 { fill: #00a148; cursor: pointer; }
.react-calendar-heatmap .saved-data-1 { fill: #6dac43; cursor: pointer; }
.react-calendar-heatmap .current-bill-calendar-data { fill: rgb(235, 146, 78); }
.react-calendar-heatmap .overlap-calendar-data { fill: rgb(171, 29, 29); }
.react-calendar-heatmap .no-data { fill: lightgray; }

svg.react-calendar-heatmap {
  overflow: visible;
}

.bill-switch-container {
  position: fixed;
  left: 30px;
  bottom: 30px;
}

.gpt-html {
  h1, h2, h3 {
  }
  table {
    width: 100%;
    border-collapse: collapse;
    margin-bottom: 20px;
  }
  table, th, td {
    border: 1px solid #ccc;
  }
  th, td {
    padding: 10px;
    text-align: left;
  }
  th {
  }
  p.note, span.note {
    display: inline-block;
    border: 1px solid #ffc265;
    background-color: #ffc265cc;
    color: #333;
    padding: 5px 10px;
    margin: 5px 0;
    border-radius: 5px;
    box-shadow: 0 2px 4px rgba(0,0,0,0.2);
  }
  p.note::before {
    content: "🤖 ";
  }
}