.request-email-container {
    display: flex;
    margin: 0px auto;
    justify-content:center;
    align-items: center;
    
    .request-email-input {
        margin: 5px;
        textarea {
            width: 100%;
            height: 100%;
        }
    }
    .template-select {
        margin-right: 10px;
    }
    button {
        margin: 5px;
    }
}

.css-1t1j96h-MuiPaper-root-MuiDialog-paper {
    max-width: 900px;
}

.css-xqhexu-MuiPaper-root-MuiAppBar-root {
    box-shadow: 0px 2px 4px -1px rgba(0,0,0,20%), 0px 4px 5px 0px rgba(0,0,0,14%);
}

.template-body-input {
    width: 99%;
    border-color: #ccc;
    border-radius: 4px;
}

.flex-container-right {
    display:flex;
    justify-content: flex-end;
}

.dark-mode {
    .alarm-request-card {
        background: #7b5002;
    }    
}

.alarm-request-card {
    background: #ffe2ad;
}


.select-container {
    display:flex;
    justify-content: center;
    align-items: center;
}
.MuiDataGrid-root .MuiDataGrid-toolbar .css-mdqim8-MuiButtonBase-root-MuiButton-root:nth-child(4) {
    // uncomment below to disable CSV export
    // display:none;
}
.blank-card {
    border: 1px solid black;
    margin: 10px auto;
    height: 300px;
    display: flex;
    align-items: center;
    justify-content: center;

}

.preview-fields {
    display:flex;
    flex-direction: column;
}

.note-button-row {
    width: 100%; 
    display: flex;
    justify-content: flex-end;
}

.inaccessible-delete-button {
    cursor: pointer;
}

.paused-automation {
    background: 'papayawhip';
}

.add-note-widget {
    display: flex;
    margin-bottom: 10px;
}

.request-container.placeholder-request-container {
    min-height: 390px;
    padding-top: 160px;
    box-sizing:border-box;
}

.request-container {
    
    border: 1px solid black;
    padding: 15px;
    margin-top: 10px;
    margin-bottom: 10px;

    .request-card-controls {
        display: flex;
        justify-content: center;
    }
    
    .request-card-bottom-row {
        display:flex;
        justify-content: space-between;
        .loa-container {
            width: 500px;
        }
    }
    .request-title-bar {
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
    }

    a:hover {
        text-decoration: none;
    }
    .form-row {
        display: flex;
        margin: 5px auto;
        justify-content: space-around;
        align-items: center;
        width: 600px;
    }

    .notes-container {
        width: 600px;
        text-align: left;
        margin: 2% auto;
    }
}

.no-mark-list {
    list-style-type: none;
}

a.action-button:hover {
    text-decoration: none;
}

.completed-request {
    background: greenyellow;
}

.follow-up-due {
    background: orange;
}

.request-list {
    max-width: 1000px;
    margin: 10px auto;
}