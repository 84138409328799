.add-property-form-container {
    max-width: 800px;
    width: 90%;
    margin: 1% auto;
    transition: .2s;
}

#save-property-button {
    margin-top: 15px;
}

.transparent {
    opacity: 0;
}